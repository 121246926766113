import { useState,useContext } from "react";
import roomheadpic from "../assets/contract.png";
import { Card } from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";
import { Steps } from "antd";
import pic from "../assets/unnamed.png";
import { Input, List } from "antd";
import { Button, Modal } from "antd";
import { NavLink, redirect  } from "react-router-dom";
import { AutoComplete } from "antd";
import { Checkbox, Descriptions } from "antd";
import axios from "axios";
import { useEffect, useRef } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { Space, Table,Spin} from "antd";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import JsPDF from "jspdf";
import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

export default function Publish({ data1, propname, gstnum }) {
  const {basic,propInput, addToBasic, locationValue, addToLocations, facilities, addToFacilities, roomDetails, addToRoomDetails, propImages, addToPropImages,propImagespath, addToPropImagespath, profileDetails, addToProfile, profileImagePath,gstvarified_details,basic_Verifiedbut,profile_acVerifiedbut,profileupiverified,ch_managerid,upi_name,
    onboardapi,profile_upi_Verifiedbut,upiNamefunc,logo_img} = useContext(PropertyContext);

 
  
  const ref1 = useRef(null);
  let data1cont = [];
  let frontofficedata = [];
  let Gmdata = [];
  let Reservation = [];
  let finance_manager = [];
  let othercontactdetail = [];
  var imgpaths=[];
  const navigate = useNavigate(); 


  const [salesdata, setsalesdata] = useState([]);
  const [frontoffdata, setfrontoffdata] = useState([]);
  const [Gmdataarr, setGmdataarr] = useState([]);
  const [financemanager, setFinancemanager] = useState([]);
  const [othersmanager, setOthersmanager] = useState([]);
  const [Reservationdata, setreservationdata] = useState([]);
  const [prname, setprname] = useState("");
  const [gstnm, setgstnm] = useState("");
  const [createdby, setCreatedby] = useState("");
  
  const [UpiId, setUpiId] = useState("");
  
  const [contactdet, setcontacdet] = useState([]);
  
  const [openmodal, setopenmodal] = useState(false);
  const [openerror,setopenerror]=useState(false)
  const [propertyexist,setpropertyexistserror]=useState(false)
  const [termsconditions, setTermsconditions] = useState(false);
  const [sucess,setsucess]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [openmodalsuccess,setopenmodalsuccess]=useState(false);
  const [responserowid,setresponserowid]=useState("")




  const [roomdet, setroomdet] = useState([]);
  let frontoff_mobileno = frontoffdata?.length > 0  ? frontoffdata[0].mobileno : salesdata?.length > 0 ? salesdata[0].mobileno  : financemanager?.length > 0  ? financemanager[0].mobileno :Gmdataarr?.length > 0  ? Gmdataarr[0].mobileno :Reservationdata?.length > 0  ? Reservationdata[0].mobileno : othersmanager?.length > 0  ? othersmanager[0].mobileno : " N/A";
  let frontoff_mail_id = frontoffdata?.length > 0  ? frontoffdata[0].Email : salesdata?.length > 0 ? salesdata[0].Email  : financemanager?.length > 0  ? financemanager[0].Email :Gmdataarr?.length > 0  ? Gmdataarr[0].Email :Reservationdata?.length > 0  ? Reservationdata[0].Email : othersmanager?.length > 0  ? othersmanager[0].Email : " N/A"
  let items = [];
  let rdet = []; 
  let locarr = [];
  let ownerdet = [];
  let cdet = [];
  
  let allmailid = [frontoffdata?.length > 0  && frontoffdata,salesdata?.length > 0 && salesdata,financemanager?.length > 0  && financemanager,Reservationdata?.length > 0  && Reservationdata].flat(2);
  let all_mail_id = [...allmailid]?.map(item => item.Email).filter(item => item !== undefined);
  let mail_id = all_mail_id.length > 0 && all_mail_id || othersmanager[0]?.Email;

  let objectemp={}
  useEffect(() => {
   
    if(navigator.userAgent.includes("Mozilla")===true)
    {
      if(basic[0]?.propertyname ==='' || basic[0]?.propertyname == undefined)
      {
        navigate("/");
       
      }

    }
    if(basic[0]?.propertyname ==='' || basic[0]?.propertyname == undefined) {
      // window.location.reload(true);
      navigate("/");

      // window.location.reload(false);
      
   }


  //  imgpaths = propImagespath.filter((o_manager) => {
  //     return ![undefined].includes(o_manager)
  //   });
  
    
    window.scrollTo(0, 0);
    window.scroll(0,20);
    let qualityamenities=facilities[0]?.CleaninessAmenities===facilities[0]?.CleaninessAmenities?.length===0?facilities[0]?.CleaninessAmenities.push("N/A"):facilities[0]?.CleaninessAmenities
   
    
    const el = document.getElementById("report");
    

    
    
    setprname(propname);
    setgstnm(gstnum);
     data1cont = profileDetails[0]?.contactarr.filter((dt) => {
       return dt.contactType == "Sales";
     });
    // salesemaillist= data1cont.filter((dt) => {
    //   return dt.Email
    // });
  
   
    frontofficedata = profileDetails[0]?.contactarr.filter((fd) => {
      return fd.contactType == "Front Office";
    });
   
    Gmdata = profileDetails[0]?.contactarr.filter((gmdt) => {
      return gmdt.contactType == "GM";
    });
    Reservation = profileDetails[0]?.contactarr.filter((rsrvation) => {
      return rsrvation.contactType == "Reservation";
    });
    finance_manager = profileDetails[0]?.contactarr.filter((f_manager) => {
      return f_manager.contactType == "Finance Manager";
    });
    othercontactdetail = profileDetails[0]?.contactarr.filter((o_manager) => {
      return !["Front Office","Sales","GM","Reservation","Finance Manager"].includes(o_manager.contactType)
    });

    setGmdataarr(Gmdata);
    
    setsalesdata(data1cont);
    setfrontoffdata(frontofficedata);
    setreservationdata(Reservation);
    setFinancemanager(finance_manager)
    setOthersmanager(othercontactdetail)
   

    items = JSON.parse(localStorage.getItem("Propbasics"));
    locarr = JSON.parse(localStorage.getItem("locationarr"));
    ownerdet = JSON.parse(localStorage.getItem("PropOwnerdet"));
    rdet = JSON.parse(localStorage.getItem("RoomDetails"));
    cdet = JSON.parse(localStorage.getItem("ContactDetails"));
    setcontacdet(cdet);
  
    setroomdet(rdet);
    const datalocality={
      cityid:locationValue[0]?.CityId,
      localityname:locationValue[0]?.localityname
    }
   
      //  const responselocality = axios
      //   .post("https://hotelonboardtestapi.staysimplyfied.com/API/insertlocality",datalocality)
      //   .then((res) => {
      //     // setnewlocalityname(locationValue[0]?.localityname)
      //     setnewLocalityId(res?.data[0]?.Id)
          
      

      //     if(res.data[0]===""){
      //       // axios.post("http://localhost:3000/api/selectlocalityid",datalocality).
      //       // then(res=>{
      //       //    
                 
      //       // }).catch(err=>{
      //       //   
      //       // })
      //     }
          
         
      //   })
      //   .catch((err) => {
      //     
      //   });

        

  }, []);
 
  imgpaths.push(propImagespath.filter(o_manager => o_manager !== undefined))
  
   
  const newroomDetail = roomdet?.map((element, i) => {
    return {
      key: element.key,
      Roomtype: element.RoomType,
      SingleRackRate: element.SingleRackRate,
      DoubleRackRate: element.DoubleRackRate,
    };

    
  });

  const handleCancel = () => {
    setopenmodal(false);
  
  };
  const handleCancelerror = () => {
    setopenerror(false);
    setpropertyexistserror(false);
    
  };
  const handleCancelsuccess = () => {
    
  //  window.location.replace('https://www.hummingbirdindia.com/');   
  // window.location.reload(true);
   navigate('/');    
    setopenmodalsuccess(false);
      addToBasic([]);
      addToLocations([]);
      addToFacilities([]);
      addToRoomDetails([]);
      addToRoomDetails([]);
      addToPropImagespath([]);
      addToPropImages([]);
      addToProfile([]);
      basic_Verifiedbut(false);
      profile_acVerifiedbut(false);
      profileupiverified(false);
      profile_upi_Verifiedbut(false);
      upiNamefunc({})
      
     
      // window.location.reload(false);
  };
  const handledashboard = () => {
   let prowid='https://hbbill.staysimplyfied.com/#/billupload/'+responserowid;
    //  window.location.replace(prowid);    
     
     window.open(prowid,'_blank');
        // setopenmodalsuccess(false);
        addToBasic([]);
        addToLocations([]);
        addToFacilities([]);
        addToRoomDetails([]);
        addToRoomDetails([]);
        addToPropImagespath([]);
        addToPropImages([]);
        addToProfile([]);
        basic_Verifiedbut(false);
        profile_acVerifiedbut(false);
        profileupiverified(false);
        
        profile_upi_Verifiedbut(false);
        // window.location.reload(false);
        upiNamefunc({})
        
    };

  
  const { Column } = Table;
  const { TextArea } = Input;
  const description = "This is a description.";
  const options = [
    {
      value: "Burns Bay Road",
    },
    {
      value: "Downing Street",
    },
    {
      value: "Wall Street",
    },
  ];

  const handleChange = (value) => {
    
  };
 
  
let btcperiod=roomDetails?.btccreditperiod===undefined?0:roomDetails?.btccreditperiod

const propimagename=propImages.map((dt) => {
  return {name:dt.name}
});
let imagedet=[];
imagedet.push([...propimagename,...imgpaths].flat(Infinity))


  const functionshow = () => {
    //setIsLoading(true);
   

    if(termsconditions === true && createdby !== "")
    {
      setIsLoading(true);
      const result = roomDetails?.roomdetarr.map(({RoomImagepreview,index,...rest}) => 
      ({...rest})
      )
     
      // const roomdetresult =result.map((res)=>{
      //   // return [res.Inclusions.toString()]
      
      // })
      
    
      
    

      // let facilities =result[0].Inclusions.flat()
    
      setopenmodal(false);
      
      
      // if(basic[0]?.oneday==="24 hrs")
      // {
      //  let ckintime = basic[0]?.CheckinTime="24"
    
      // }
   
      // setopenmodal(true);
      // setopenmodalsuccess(true)
  
      const data = {
        PropertyName: basic[0]?.propertyname,
        CheckinTime: basic[0]?.oneday === "24 hrs" ? "24" : basic[0]?.checkintime,
        CheckinType:  basic[0]?.oneday === "24 hrs"?"Hrs":basic[0]?.checkintypeampm,
        CheckOutTime: basic[0]?.oneday === "24 hrs" ? "24":basic[0]?.checkouttime,
        CheckOutType: basic[0]?.oneday === "24 hrs"?"Hrs":basic[0]?.checkouttypeampm,
        Gstnumber: basic[0]?.gstnumber,
        legalname:basic[0]?.legalname,
        Propertydescription:basic[0]?.propdescription,
        propertytype:basic[0]?.propertytype < 6 ?basic[0]?.propertytype+1:basic[0]?.propertytype, 
        Channelmanager:basic[0]?.Channelmanager ==="Others"?basic[0].otherstext:basic[0]?.Channelmanager===undefined?"":basic[0]?.Channelmanager,
        // cancellationpolicy:`The guest must cancel by ${
        //   basic[0]?.cancelpolicy1 !== "1 day" ? basic[0]?.cancelpolicy1 : "1 day"
        // } on the day of arrival or pay 100% of the price  ${
        //   basic[0]?.cancelpolicy2 !== "" ? basic[0]?.cancelpolicy2 : "Room night"
        // }`,
        cancellationpolicy:basic[0]?.cancellationpolicy,
        gststatus:basic[0]?.gststatus,
        Addrsline1:locationValue[0]?.Addrsline1,
        Addrsline2:locationValue[0]?.Addrsline2,
        State:locationValue[0]?.Statename,
        City:locationValue[0]?.Cityname,
        Locality:locationValue[0]?.localityname,
        StateId:locationValue[0]?.StateId,
        CityId: locationValue[0]?.CityId,
        localityId:locationValue[0]?.localityId,
        Pincode:locationValue[0]?.Pincode,
        Directions:locationValue[0]?.Directions,
        latlng:locationValue[0]?.latlng.lat+","+locationValue[0]?.latlng.lng,
        localityname: locationValue[0]?.localityname,
        AccountType:profileDetails[0].Propertyowners[0]?.AccountType,
        Accountnumber:profileDetails[0].Propertyowners[0]?.Accountnumber,
        Bankname: profileDetails[0].Propertyowners[0]?.Bankname,
        IFSCcode: profileDetails[0].Propertyowners[0]?.IFSCcode,
        Payeename: profileDetails[0].Propertyowners[0]?.Payeename,
        UpiId: profileDetails[0].Propertyowners[0]?.UpiId,
        Upimobilenumber:profileDetails[0].Propertyowners[0]?.Upimobilenumber,
        Upiname:profileDetails[0].Propertyowners[0]?.UpiId === ''? '' : profileDetails[0].Propertyowners[0]?.Upiname===undefined?'':profileDetails[0].Propertyowners[0]?.Upiname,
        branchAddress: profileDetails[0].Propertyowners[0]?.branchAddress,
        checkleafpath: profileImagePath[0],
        roomdet: result,
        contactdet: profileDetails[0]?.contactarr,
        btccreditperiod:btcperiod,
        btc:btcperiod > 0 ? 1 : 0,
        Hygienictowelsandblanket:facilities[0]?.Cleanliness_check_result[0],
        Freefacemasks:facilities[0]?.Cleanliness_check_result[1],
        Freeofpests:facilities[0]?.Cleanliness_check_result[2],
        CleanedbyprofessionalcleaningTeam:facilities[0]?.Cleanliness_check_result[3],
        Handsanitizer:facilities[0]?.Cleanliness_check_result[4],
        WaterandElectricity:facilities[0]?.Cleanliness_check_result[5],
        LaundryServices:facilities[0]?.Other_info_check_result[0],
        CyberCafeWifi:facilities[0]?.Other_info_check_result[1],
        ConferenceHall:facilities[0]?.Other_info_check_result[2],
        RestaurtantServices:facilities[0]?.Other_info_check_result[3],
        SwimmingPoolServices:facilities[0]?.Other_info_check_result[4],
        GymServices:facilities[0]?.Other_info_check_result[5],
        TourAssistance:facilities[0]?.Other_info_check_result[6],
        PetFriendly:facilities[0]?.Other_info_check_result[7],
        Parking:facilities[0]?.Other_info_check_result[8],
        WomenSafety:facilities[0]?.safety_check_result[0],
        CCTVsurveillance:facilities[0]?.safety_check_result[1],
        Contactlesscheckin:facilities[0]?.safety_check_result[2],
        Securityguard:facilities[0]?.safety_check_result[3],
        Fireextinguisher:facilities[0]?.safety_check_result[4],
        Emergencyexit:facilities[0]?.safety_check_result[5],
        imagepath1:imgpaths[0],
        imagename1:propImages?.name,
        cancalpolicy1:basic[0]?.cancelpolicy1,
        cancalpolicy2:basic[0]?.cancelpolicy2,
       chnmanagerid:ch_managerid > 0 ? ch_managerid : basic[0]?.chnlmanagerid || "",
       //basic[0]?.chnlmanagerid ===undefined?ch_managerid:basic[0]?.chnlmanagerid,
       // chnmanagerid:(basic[0]?.otherchnlmanagerid ===undefined && basic[0]?.chnlmanagerid ===undefined) ? ch_managerid:(basic[0]?.otherchnlmanagerid||basic[0]?.chnlmanagerid),
        safetyamenities: facilities[0]?.safetyAmenities,
        QualityAmenities:facilities[0]?.CleaninessAmenities,
        HBAddress:locationValue[0]?.HBAddress,
        tradename:basic[0]?.tradename===undefined?'':basic[0]?.tradename,
        imagename:propimagename,
        imagedet:imgpaths[0],
        websiteAddress:basic[0]?.websiteAddress===undefined?'':basic[0]?.websiteAddress,
        stategstnum:locationValue[0].stategstnum,
        mail_id:mail_id,
        gst_payeeflag:profileDetails[0]?.gst_payeename_verifi == true ? 1 : 0,
        createdby: createdby.charAt(0).toUpperCase() + createdby.slice(1) 
      };


//property create data to mail
  //     const propertydata = axios.post(`${onboardapi}/propertydata`, data)

  // .then((res) => {}).catch((err) => {})
      
    //  console.log(data,'data')

      const propresponse = axios 
          .post(`${onboardapi}/propertyinsert`, data)
          // .post("https://hotelonboardtestapi.staysimplyfied.com/API/propertyinsert",data)
      // .post("http://localhost:3000/api/propertyinsert", data)
      .then((res) => {
      
          if(res?.data.data.Msg==="Hotel Created Successfully")
          {
            setIsLoading(false);
            
            setTimeout(() => {
              // window.location.reload(true);
              navigate('/'); 
            }, 20000);
            
            setresponserowid(res?.data.data.RowId);
            setopenmodal(false)
            setopenerror(false)
            setopenmodalsuccess(true)
            
            setIsLoading(false);
            addToBasic([]);
            addToLocations([]);
            addToFacilities([]);
            addToRoomDetails([]);
            addToRoomDetails([]);
            addToPropImagespath([]);
            addToPropImages([]);
            addToProfile([]);
            basic_Verifiedbut(false);
            profile_acVerifiedbut(false);
            profileupiverified(false);
            profile_upi_Verifiedbut(false);
            upiNamefunc({})
           
           
            
          } else if (res?.data.data.Msg==="Hotel Name already Exist") {
            setIsLoading(false);
            setpropertyexistserror(true);
          }
         
            // if(res?.data[0][0]?.errmsg==="ALREADY EXISTS")
            // {
            //   setopenmodal(false)
            //   setopenerror(true)
              
            // }

          
                    
          setsucess(true);   
      
        })
        .catch((err) => {
       
          setopenmodal(false)

          // setopenerror(true)
        });
    }
    else{
      setopenmodal(true)
    }
  };

  const propertysave = () => {
    
   
  };
  
  
  const data = [
    {
      key: "1",
      Sno: "1",
      name: "Client:",
      description:
        "M/s HummingBirdDigitalPvt. Ltd., 122 Amarjyothi Layout, Domlur, Bangalore – 560071, Karnataka",
    },
    {
      key: "2",
      Sno: "2",
      name: "Service Provider Name & Address:",
      description: "",
    },
    {
      key: "3",
      Sno: "3",
      name: "Service Provided:",
      description: "",
    },
    {
      key: "4",
      Sno: "4",
      name: "Service Provided:",
      description: "",
    },
  ];

  const datasource = [

    {
      key: "1",
      Particulars: <b>GSTIN</b>,
      numbername: "",
      singletariff: gstvarified_details &&  gstvarified_details?.gstin,
    },
    {
      key: "2",
      Particulars: <b>Taxpayer Legal Name</b>,
      numbername: "",
      singletariff:gstvarified_details &&  gstvarified_details?.lgnm,
    },
    {
      key: "3",
      Particulars: <b>Taxpayer Trade Name</b>,
      numbername: "",
      // singletariff:gstvarified_details &&  ": "+ gstvarified_details?.tradeNam===""?gstvarified_details?.tradeNam : ": N/A",
      singletariff:basic[0]?.tradename===undefined? "N/A":basic[0]?.tradename,
    },
   
  ];
  const checkinout = [];
 
  const generatePDF = () => {
    // const report = new JsPDF({
    //   orientation: 'p',
    //   unit: 'pt',
    //   format: 'a4',
    //   putOnlyUsedFonts:true,
    //   compress:true,
    //  })

    //  report.setDisplayMode('100%','single')
    // const header = 'Hummming bird';
    // report.html()
    // .then(() => {
    //   // report.text('Hummming bird', 200, 50)
    //   // report.setPage(2)

    //   report.save('report.pdf');
    // }).catch(err=>{
    
    // })

    // var width = report.internal.pageSize.width(250);
    // var height=report.internal.pageSize.getHeight();
    

    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    let element = document.getElementById("report");
    var worker = html2pdf().from(element).set(opt).save();

    //   report.html(document.querySelector('#report'), {
    //     callback: function (report) {
    //       report.save();
    //     },
    //     x: 10,
    //     y: 10,
    //     autoPaging:'slice',
    //     windowWidth:750
    //  });
  };

  const onChangechecktime = (e) => {
      if(e.target.checked === true) 
      {
          setTermsconditions(true);  
      }
      else 
      {
      setTermsconditions(false); 
      }

  }
// useEffect(()=> {
  
  
// },[]);

  return (
    <div>
       {isLoading && (
        <div className="loaderloading">
          <div className="example">
    <Spin /> <span style={{marginLeft:'10px',fontWeight:'bold'}}>Loading ...</span>
  </div>
        </div>
      )}
      <nav className="row">
      <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
          <img
            className="3 mt-1"
            src={logo_img}
            alt=""
            width={"120px"}
          />
        </a>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon">fsfds</span>
      </button> */}
        <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
          <h3>
            <b>Property Onboarding</b>
          </h3>
        </span>
      </nav>
      <div className="container-fluid publish_blk">
        <div className="row">
        <div className="col-md-2 no-border-l h100" style={{background:'#fff',borderLeft:"none"}}>
          <Card className=" col-md-12 h-100 side-menu">
            <Steps
              size="small"
              className="mt-md-5 cursor-not-allowed mobile-hide"
              direction="vertical"
              current={6}
            >
              <Steps.Step title={<NavLink to="/">Basics</NavLink>} />
              <Steps.Step title={<NavLink to="/location">Location</NavLink>} />
              <Steps.Step
                title={<NavLink to="/facilities">Facilities</NavLink>}
              />
              <Steps.Step
                title={<NavLink to="/roomdet">Room details</NavLink>}
              />
              <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
              <Steps.Step title={<NavLink to="/Profile">Contact</NavLink>} />
              <Steps.Step title={<NavLink to="/Publish">Publish</NavLink>} />
            </Steps>
            <a className="navbar-brand col-md-2 text-center logo-display" href="#">
            <img
              className="logo_name"
              src={logo_img}
              alt=""
            />
          </a>
          <p className="mt-sm-3 deskbrowser" style={{fontSize: "12px"}}><span className="fw-bold" style={{fontSize: "14px",marginBottom:"10px"}}>Supported Browsers : </span><br/> <span style={{margin:"0px",lineHeight:"10px"}}></span>1. Google Chrome,<br/>2. Microsoft Edge,<br/>3. Firefox.</p>
          </Card>
          {/* <div className='d-flex justify-content-center'> */}
          </div>
          <div
            className="col-md-10 layout-ml"
            style={{ background: "rgb(227 245 255)" }}
          >
            <div className="row" style={{ marginTop:"10px" }}>
              <div className="col-sm-6 p-3">
                <h3>Sign your contract and let’s get booking.</h3>
                <p>Just one more step to complete.</p>
              </div>
              <div className="col-sm-6">
              <img src={roomheadpic} alt="" className="mt-2" height={"120px"} />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-lg-9" id="report" ref={ref1}>
                <h5>
                  MOU / Service Agreement for Business STAY Management Services.
                </h5>

                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}
                >
                  {/* <label>Room Type</label> */}

                  <div className="row">
                    <div className="col-sm-12 publish p-0">
                  

                      <div>
                        <Descriptions bordered layout={"vertical"}>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Company Name:</span>
} style={{verticalAlign: "top",width:"33%"}}>
                            <span style={{fontWeight: 'bold'}}>HummingBird Digital Pvt Ltd,</span>
                            <br/>
                            <span style={{width:"74px",display:"inline-block",fontWeight:'bold'}}>GSTIN </span>:&nbsp;
                             {locationValue[0]?.stategstnum  || '29AABCH5874R1ZN'}
                             <br/>
                             
                             <span style={{width:"74px",display:"inline-block",float: "left"}}> Regd Office</span> : {locationValue[0]?.HBAddress || " No.8/4, 3rd Floor, Gurumarthappa Garden, 6th Cross, Sarakki Main Road, 1st Phase, J.P. Nagar, Bangalore 560078."}
                                                                                             
                          </Descriptions.Item>
                          <Descriptions.Item 
                          label={
                            <span style={{fontWeight: 'bold'}}>Property Name and Address:</span>
                      }style={{verticalAlign: "top",width:"33%"}}>
                            <li><b>{basic[0]?.propertyname}</b></li>
                            <li>
                            {locationValue[0]?.Addrsline1+", "+locationValue[0]?.Addrsline2+", "+locationValue[0]?.localityname+", "+locationValue[0]?.Cityname+", "+locationValue[0]?.Statename+" - "+locationValue[0]?.Pincode}
                               
                            </li>
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Service Provided:</span>
}style={{verticalAlign: "top",width:"33%"}}>
                            <ol type="1" style={{paddingLeft:"10px"}}>
                              <li>
                                Provide rooms for transit stay for corporate
                                clients & retail
                              </li>
                              <li>Provide rooms for long stay</li>
                              <li>Provide meals & Wifi to the traveler</li>
                              <li>
                                Provide conference room facility if available
                              </li>
                              <li>Provide car park if available</li>
                            </ol>
                          </Descriptions.Item>
                          {/* {facilities[0]?.CleaninessAmenities !== undefined  && <Descriptions.Item label={ */}
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Quality Requirements:</span>
}style={{verticalAlign: "top"}}>
                            <ol type="1" style={{paddingLeft:"10px"}}>
                              {facilities[0]?.CleaninessAmenities !== undefined ? facilities[0]?.CleaninessAmenities?.map((item,index) => <li key={index}>{item}</li> ) : "N/A"}
                              
                              
                            </ol>
                            </Descriptions.Item> 
                          {/* </Descriptions.Item> } */}
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Safety Requirements:</span>
}style={{verticalAlign: "top"}}>
                            <ol style={{paddingLeft:"10px"}}>
                              
                              {facilities[0]?.safetyAmenities !== undefined ? facilities[0]?.safetyAmenities.map((item,index) => <li key={index}>{item}</li> ) : "N/A"}
                            </ol>
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Payment Terms:</span>}>
      <ol type="1" style={{paddingLeft:"10px"}}>
                              <li>If the traveler pay at the property. The business fee
                            agreed on will be transferred to HB</li>
                              
                              <li>If HB collect the payment, HB will process the payment to property as per credit period</li>
                            </ol>


                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>HB Business Fee:</span>
}>
                            Refer Annexure A
                          </Descriptions.Item>
                          
                          
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Front Office:</span>
}style={{verticalAlign: "top"}}>  
                          
                          <span style={{width:"67px",display:"inline-block"}}>Name </span>: {" "}
                            {frontoffdata?.length > 0  ? frontoffdata[0].contactName  : salesdata?.length > 0 ? salesdata[0].contactName : financemanager?.length > 0  ? financemanager[0].contactName : Gmdataarr?.length > 0  ? Gmdataarr[0].contactName :Reservationdata?.length > 0  ? Reservationdata[0].contactName : othersmanager?.length > 0  ? othersmanager[0].contactName : " N/A"}
                            <br />
                            <span style={{width:"67px",display:"inline-block"}}>Mobile No  </span>:{" "}
                            {frontoffdata?.length > 0  ? frontoffdata[0].mobileno : salesdata?.length > 0 ? salesdata[0].mobileno  : financemanager?.length > 0  ? financemanager[0].mobileno :Gmdataarr?.length > 0  ? Gmdataarr[0].mobileno :Reservationdata?.length > 0  ? Reservationdata[0].mobileno : othersmanager?.length > 0  ? othersmanager[0].mobileno : " N/A"}
                            <br /><span style={{width:"67px",display:"inline-block"}}>Email </span>: {" "}
                            {frontoffdata?.length > 0  ? frontoffdata[0].Email : salesdata?.length > 0 ? salesdata[0].Email  : financemanager?.length > 0  ? financemanager[0].Email :Gmdataarr?.length > 0  ? Gmdataarr[0].Email :Reservationdata?.length > 0  ? Reservationdata[0].Email : othersmanager?.length > 0  ? othersmanager[0].Email : " N/A"}
                            
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Sales:</span>
}style={{verticalAlign: "top"}}>
                           
                           <span style={{width:"67px",display:"inline-block"}}>Name </span>: {" "}
                            {salesdata?.length > 0 ? salesdata[0].contactName : " N/A"}
                            <br />
                            <span style={{width:"67px",display:"inline-block"}}>Mobile No  </span>:{" "}{" "}
                            {salesdata?.length > 0 ? salesdata[0].mobileno : " N/A"}
                            <br />
                            <span style={{width:"67px",display:"inline-block"}}>Email </span>: {" "}
                            {salesdata?.length > 0 ? salesdata[0].Email : " N/A"}

                            
                            
                            
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>GM:</span>
}>  
                         
                         <span style={{width:"67px",display:"inline-block"}}>Name </span>: {" "} 
                            {Gmdataarr?.length > 0  ? Gmdataarr[0].contactName  : " N/A"} 
                            <br />
                            <span style={{width:"67px",display:"inline-block"}}>Mobile No  </span>:{" "}
                            {Gmdataarr?.length > 0  ? Gmdataarr[0].mobileno  : " N/A"}
                            <br /><span style={{width:"67px",display:"inline-block"}}>Email </span>: {" "} 
                            {Gmdataarr?.length > 0  ? Gmdataarr[0].Email  : " N/A"}
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Reservation:</span>
}>  
                          
                            
                          <span style={{width:"67px",display:"inline-block"}}>Name </span>: {" "}
                                {Reservationdata?.length > 0  ? Reservationdata[0].contactName : " N/A"}{" "}
                                <br />
                                <span style={{width:"67px",display:"inline-block"}}>Mobile No  </span>:{" "}
                                {Reservationdata?.length > 0  ? Reservationdata[0].mobileno : " N/A"}
                                <br />
                                <span style={{width:"67px",display:"inline-block"}}>Email </span>: {" "}
                                {Reservationdata?.length > 0  ? Reservationdata[0].Email  : " N/A"}
                              
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Finance Manager:</span>
}>  
                            <span style={{width:"67px",display:"inline-block"}}>Name </span>: {" "} {financemanager?.length > 0  ? financemanager[0].contactName  : " N/A"} 
                            <br />
                        
                            <span style={{width:"67px",display:"inline-block"}}>Mobile No  </span>:{" "} {financemanager?.length > 0  ? financemanager[0].mobileno  : " N/A"} 
                            <br />
                            <span style={{width:"67px",display:"inline-block"}}>Email </span>: {" "} {financemanager?.length > 0  ? financemanager[0].Email : " N/A"} 
                            <br />
                          </Descriptions.Item>
                          <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Bank Details:</span>
}style={{verticalAlign: "top"}}>                          
{/* <span style={{width:"100px",display:"inline-block"}}>Account No </span> : {profileDetails[0]?.Propertyowners[0]?.Accountnumber} */}
                          

                            <table style={{verticalAlign: "top"}}>
                              <tr style={{verticalAlign: "top"}}>
                                <td style={{verticalAlign:"top",width:'105px'}}>Account No</td>
                                <td>: {profileDetails[0]?.Propertyowners[0]?.Accountnumber}</td>
                              </tr>
                              <tr style={{verticalAlign: "top"}}>
                                <td style={{verticalAlign:"top",width:'105px'}}>IFSC Code </td>
                                <td>: {profileDetails[0]?.Propertyowners[0]?.IFSCcode}</td>
                              </tr>
                              <tr style={{verticalAlign: "top"}}>
                                <td style={{verticalAlign:"top",width:'105px'}}>Payee Name </td>
                                <td>: {profileDetails[0]?.Propertyowners[0]?.Payeename}</td>
                            
                              </tr>
                              <tr style={{verticalAlign: "top"}}>
                                <td style={{verticalAlign:"top",width:'105px'}}>Bank Name</td>
                                <td>: {profileDetails[0]?.Propertyowners[0]?.Bankname}</td>
                              </tr>
                              <tr style={{verticalAlign: "top"}}>
                                <td style={{verticalAlign:"top",width:'105px'}}>Account Type </td>
                                <td>: {profileDetails[0]?.Propertyowners[0]?.AccountType}</td>
                              </tr>
                              <tr style={{verticalAlign: "top"}}>
                                <td style={{verticalAlign:"top",width:'105px'}}>Branch Address </td>
                                <td>: {profileDetails[0]?.Propertyowners[0]?.branchAddress || "N/A"}</td>
                       
                              </tr>
                            </table>
                            {/* <span style={{width:"100px",display:"inline-block"}}>IFSC Code</span> : {profileDetails[0]?.Propertyowners[0]?.IFSCcode}
                            <br />
                            <span style={{width:"100px",display:"inline-block"}}>Payee Name</span>  : {profileDetails[0]?.Propertyowners[0]?.Payeename}
                            <br />
                            <span style={{width:"100px",display:"inline-block"}}>Bank Name</span> : {profileDetails[0]?.Propertyowners[0]?.Bankname}
                            <br />
                            <span style={{width:"100px",display:"inline-block"}}>Account Type</span> : {profileDetails[0]?.Propertyowners[0]?.AccountType}
                            <br />
                            <span style={{width:"100px",display:"inline-block"}}>Branch Address</span> : {profileDetails[0]?.Propertyowners[0]?.branchAddress || "N/A"} */}
                          </Descriptions.Item>
                          
                          
                         
                        </Descriptions>
                        <hr/>
                      </div>
                      <p className="mt-md-4 ms-sm-2 mt-sm-2">
                        This is in principle acceptance of terms and conditions.
                        <br />
                        We may enter into a formal service agreement on mutual
                        agreed terms & conditions.
                      </p>
                      <p className="ms-sm-2"> </p>

                      <div className="row">
                        <div className="col-sm-7">
                          <p className="ms-sm-2">
                            For <b>HummingBird Digital Pvt Ltd</b>
                          </p>
                        </div>
                        <div className="col-sm-5">
                          <p className=" float-end d-inline-block">for, <b>{basic[0]?.legalname}</b></p>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                      <h3 className="text-center" style={{fontSize:"15px"}}>ANNEXURE A</h3>

                      <Descriptions bordered layout={"vertical"}>
                      <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Property&nbsp;Name:</span>
}style={{width:"33%"}}>  
                          {/* {prname} */}
                          {basic[0]?.propertyname}
                        </Descriptions.Item>
                        <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Property Address:</span>}style={{width:"33%"}}>

{locationValue[0]?.Addrsline1+", "+locationValue[0]?.Addrsline2+", "+locationValue[0]?.localityname+", "+locationValue[0]?.Cityname+", "+locationValue[0]?.Statename+" - "+locationValue[0]?.Pincode}
      </Descriptions.Item>
                        <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Property Contact Details : Front Office</span>}style={{verticalAlign: "top",width:"33%"}}>

                          <div className="row">
                          <div className="col-sm-12"><span style={{width:"67px",display:"inline-block"}}> Mobile No</span> : {frontoff_mobileno}</div>
                          </div>
                          <div className="row">
                          <div className="col-sm-12"><span style={{width:"67px",display:"inline-block"}}>Email </span> : {frontoff_mail_id}</div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions className="publish_full-width">
                        <Descriptions.Item>
                          <div className="row">
                            <div
                              className="col-12 col-sm-12 col-lg-6 col-md-6 publish_column"
                              style={{ float: "left" }}
                            >
                              <Descriptions bordered layout={"vertical"}>
                              <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>GSTIN :</span>
    }
      >
                                  <Table dataSource={datasource} className="gsttan publish_tab" pagination={false} showHeader={false} >
                                    <Column
                                     
                                      render={(_, record) => (
                                        <Space size="middle">
                                          <p> {record.Particulars}</p>
                                        </Space>
                                      )}
                                    />
                                    <Column
                                     
                                      render={(_, record) => (
                                        <Space size="middle">
                                          <p>: {record.singletariff}</p>
                                        </Space>
                                      )}
                                    />
                                  </Table>
                                </Descriptions.Item>
                              </Descriptions>
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-6 col-lg-6 publish_column"
                              style={{ float: "left" }}
                            >
                              <Descriptions bordered layout={"vertical"}>
                              <Descriptions.Item label={
      <span style={{fontWeight: 'bold'}}>Check-In & Check-Out Time:</span>}>
        <div>  <>

        <p style={{paddingLeft:"10px"}}><b>Check-In &nbsp;&nbsp;&nbsp;: </b>{basic[0]?.oneday === "24 hrs" ? "24 hrs" : basic[0]?.checkintime +" "+ basic[0]?.checkintypeampm}</p> 
        <p style={{paddingLeft:"10px"}}><b>Check-Out : </b>{basic[0]?.oneday === "24 hrs" ? "24 hrs" : basic[0]?.checkouttime +" "+ basic[0]?.checkouttypeampm}</p>


        {/* <p style={{paddingLeft:"10px"}}><b>Check-In : </b>{basic[0]?.checkintime ===" "?"24":basic[0]?.checkintime +" "+ basic[0].oneday==="24 hrs"?"Hrs":basic[0]?.checkintypeampm}</p> 
        <p style={{paddingLeft:"10px"}}><b>Check-Out : </b>{basic[0]?.checkouttime===" "?"24":basic[0]?.checkintime +" "+ basic[0].oneday==="24 hrs"?"Hrs":basic[0]?.checkintypeampm}</p> */}

        <hr />
        
        </>
         <p  style={{paddingLeft:"10px",marginTop: "10px"}}><b>BTC / Credit Period :</b> {roomDetails?.btccreditperiodoption==="Yes"? roomDetails?.btccreditperiod+" days from invoice submission":"0 days from invoice submission"}</p> </div>
        <span style={{fontWeight: 'bold',marginLeft:"10px"}}>Cancellation Policy : </span>
        <p style={{marginLeft:"10px"}}>{basic[0]?.cancellationpolicy}</p>
        
      </Descriptions.Item>
      
                              </Descriptions>
                            </div>
                          </div>
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions className="Tariff">
                        
                        <div className="Tariff_block">
                        <Descriptions.Item>
                          <Table dataSource={roomDetails?.roomdetarr} pagination={false} className="publish_tab publish_table">
                            <Column
                              title="Room Type"
                              key="roomtype"
                              style={{ minWidth: "100px",border:"1px solid #bbb" }}
                              align="center"
                              render={(_, record) => (
                                <Space size="middle">
                                  <p>{record.RoomType}</p>
                                </Space>
                              )}
                            />
                            <ColumnGroup
                            title="Rack Tariff (INR)">
                              <Column
                                title="Single"
                                key="numbername"
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                      <span style={{width:"60px",textAlign:"right",display:"block"}}>{record.SingleRackRate}</span>
                                  </Space>
                                )}
                                style={{minWidth:'100px'}}
                              />

                              <Column
                                title="Double"
                                key="numbername"
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <span style={{width:"60px",textAlign:"right",display:"block"}}>{record.DoubleRackRate}</span>
                                  </Space>
                                )}
                              />
                            </ColumnGroup>
                            <ColumnGroup
                            title="Discount Tariff (INR)">

                              <Column
                                title="Single"
                                key="numbername"
                                align="center"
                                render={(_, record) => (
                                  <Space size="middle">
                                    <span style={{width:"60px",textAlign:"right",display:"block"}}>{record.SingleDiscountRate}</span>
                                  </Space>
                                )}
                              />

                              <Column title="Double" key=""
                              align="center"
                              render={(_, record) => (
                                <Space size="middle">
                                  <span style={{width:"60px",textAlign:"right",display:"block"}}>{record.DoubleDiscountRate}</span>
                                </Space>
                              )} />
</ColumnGroup>
                            <Column
                              title="Tariff Include Taxes"
                              key="numbername"
                              align="center"
                              render={(_, record) => (
                                <Space size="middle">
                                  {record.trafficoption}
                                </Space>
                              )}
                              className=""
                            />
                            <Column
                              title="Inclusions"
                              key="numbername"
                              align="center"
                              render={(_, record) => (
                                <Space size="middle">
                                  {record.Inclusions.map((item,index) => index < record.Inclusions.length -1 ? item+", " : item)}
                                </Space>
                              )}
                              className="inclusions_block"
                            />
                            <Column
                              title="TAC %"
                              key="numbername"
                              align="center"
                              render={(_, record) => (
                                <Space size="middle">
                                  <span style={{width:"40px",textAlign:"right",display:"block"}}>{record.Tacper}</span>
                                </Space>
                              )}
                            />
                          </Table>
                        </Descriptions.Item>
                        </div>
                      </Descriptions>
                      <Descriptions className="kindly_note">
                        <Descriptions.Item >
                          
                        <p style={{fontWeight:"bold"}}>Kindly Note :</p>
                          <ul>
                          <li>
                            Capture GSTIN as per the HB booking requests, property should rectify the GSTIN in-case of any error found.
                            </li>
                            <li>
                            Any loss of ITC due to delay filing of GSTIN by the property, needs to be reimbursed to HB.
                            </li>
                            <li>
                            GSTIN will be applicable on TAC (Travel Agent Commission).
                            </li>
                            <li>
                            No-Show / cancellation retention not to be charged. If the No-Show / cancellations are high, it should be mutually discussed to handle future bookings.
                            </li>
                            <li>
                            If HB has mark-up (Up-Sell) the agreed tariff and charged the client, which must be collected at the property.
                            </li>
                            <li>
                            The mark-up amount (commission) collected by the property must be paid to HB against HB TAC Invoice.
                            </li>
                            <li>
                            TAC / Mark-up collected to be paid by the property in 10 days from the date of invoice.
                            </li>
                            <li>
                            In-case of short stay / early check-out, property have to refund / Adjust advances paid (if any) with the future bookings.
                            </li>
                          </ul>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mt-2 col-lg-9">
                <h5>Accept the terms and conditions</h5>
                <Card  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                  }}><div>Created by<span style={{color:'red'}}> *</span> :  <Input
                          placeholder="Created by"
                          className="mt-sm-2 contact_Name"
                          name="contactName"
                          style={{
                            width: "50%",
                            background:'#fff'
                          }}
                          value={createdby}
                          onChange={(event) => {setCreatedby(event.target.value)
                            
                          }}
                          onInput={propInput}
                          maxLength={50}
                        ></Input></div></Card>
                <Card
                  style={{
                    background: "#f4f4f4",
                    boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    marginTop: "10px"
                  }}
                >
                  <Checkbox value="Hand sanitizer" onChange={onChangechecktime}>
                    The General terms and conditions apply to all of your
                    properties listed through HB extranet. <span style={{color:'red'}}> *</span>
                  </Checkbox>
                </Card>
                
                <div className="float-end mt-3 mt-sm-3 mb-4">
                  <Button
                    type="primary"
                    style={{ minWidth: "100px", marginRight: "5px" }}
                    className="dblclick"
                  >
                    <NavLink to="/Profile">Previous</NavLink>
                  </Button>

                  <Button
                    type="primary"
                    className="ms-sm-3 dblclick"
                    onClick={functionshow}
                    style={{ minWidth: "100px" }}
                  >
                    Submit
                  </Button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <Modal title="Error message" className="error_msg" open={openmodal} onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" className="dblclick" onClick={handleCancel}>
            Close
          </Button>,
        ]}
        maskClosable={false}
        >
          {createdby === "" && <p> * Please enter created by </p> }
        {termsconditions === false && <p> * Please accept the terms & conditions </p> }
        

        </Modal>
        <Modal className="success_msg" title="We will now review your property details." open={openmodalsuccess} onCancel={handleCancelsuccess}
        footer={[
          <Button key="back" type="primary" className="dblclick float-start" onClick={()=>{ 
            // window.location.reload(true);
       
            navigate('/');
            }}>
            Close
          </Button>,
          <Button key="back" type="primary" className="dblclick" onClick={handleCancelsuccess}>
            Upload another property
          </Button>,
           <Button key="back" type="primary" className="dblclick mt-2 mt-md-0" onClick={handledashboard}>
          Dashboard
         </Button>,
        ]}
        maskClosable={false}
        >
          <li style={{listStyle: "none"}}>We will review your property and activate for reservations.</li>
         <li style={{listStyle: "none"}}>In the meantime, visit the dashboard</li> 

        </Modal>
        <Modal title="Error message"  className="error_msg" open={openerror} onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" className="dblclick" onClick={handleCancelerror}>
            Close
          </Button> 
        ]}
        maskClosable={false}
        >
         <p> * Property has been created already </p>
        </Modal>
        <Modal title="Error message"  className="error_msg" open={propertyexist} onCancel={handleCancelerror}
        footer={[
          <Button key="back" type="primary" className="dblclick" onClick={handleCancelerror}>
            Close
          </Button> 
        ]}
        maskClosable={false}
        >
         <p> * Hotel Name already Exist </p>
        </Modal>
      </div>
    </div>
  );
}
