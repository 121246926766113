import React from "react";
import { useState, useContext } from "react";
import {
  Card,
  Steps,
  Button,
  Popconfirm,
  Input,
  Modal,
  AutoComplete,
  Upload,
  Spin,
  Space,
  Table,
  Select,
} from "antd";
import "../styles/propinfo.css";
import "antd/dist/antd.css";

import photoheadpic from "../assets/ec-pricing@2x.png";
import { DeleteOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";

import { NavLink, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import axios from "axios";

import { PropertyContext } from "../../HB Components/propertyContext/PropertyContext";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function Profile({ setData1 }) {
  const {
    profileDetails,
    addToProfile,
    paymentVerified,
    payment_verified,
    upiverified,
    upi_verified,
    profileacverified,
    profile_acVerifiedbut,
    profileupiverified,
    profile_upi_Verifiedbut,
    upi_name,
    upiNamefunc,
    bankac_name,
    bankNamefunc,
    profileImagePath,
    profileImagePathfunc,
    textNum,
    propInput,
    multispacetrim,
    basic,
    onboardapi,
    logo_img
  } = useContext(PropertyContext);

  const { Column } = Table;
  const navigate = useNavigate();

  useEffect(() => {
    if (basic[0]?.propertyname === "" || basic[0]?.propertyname === undefined) {
      navigate("/");
    }
  }, []);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [validgsttradename, setValidgsttradename] = useState(false);
  //let validgsttradename = false;
  const [file, setfile] = useState();
  const [errormessagearrpayee, seterrormessagearrpayee] = useState([]);
  const [tradeerroropen, settradeerroropen] = useState(false);

  const options = [
    {
      value: "Front Office",
    },
    {
      value: "Sales",
    },
    {
      value: "GM",
    },
    {
      value: "Reservation",
    },
    {
      value: "Finance Manager",
    },
  ];

  const onChangeautocomplete = (data) => {
    // setClicked(false);

    setcontacttypeval(data.replaceAll("  ", " "));
    setDataChange("You have unsaved data, do you want to continue?");
  };
  const [errorcontacttype, seterrorcontacttype] = useState("");
  const [errorcontactName, seterrorcontactName] = useState("");
  const [errorEmailId, seterrorEmailid] = useState("");
  const [errormobilenumber, seterrormobilenumber] = useState("");
  const [contacttypeval, setcontacttypeval] = useState("Front Office");
  const [contactName, setcontactName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobileno, setMobileno] = useState("");
  const [landlineno, setlandlineno] = useState("");

  const [open, setOpen] = useState(false);
  const [Payeename, setPayeename] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.Payeename
  );
  const [Bankname, setBankname] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.Bankname
  );
  const [Accountnumber, setAccountnumber] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.Accountnumber
  );
  const [IFSCcode, setIFSCcode] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.IFSCcode
  );
  const [AccountType, setAccounttype] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.AccountType
  );
  const [branchAddress, setbranchaddress] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.branchAddress
  );
  const [chkleafpath, setchkleafpath] = useState("");
  const [UpiId, setUpiid] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.UpiId
  );
  const [Upiname, setUpiname] = useState("");
  const [Upimobilenumber, setUpimobilenumber] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.Upimobilenumber
  );
  const [Authorization, setAuthorization] = useState("");

  const [errorPayeename, seterrorPayeename] = useState("");

  const [erroraccountno, seterroraccountno] = useState("");
  const [errorupiId, seterrorupiid] = useState("");

  const [errormessagearr, setmessagearr] = useState([]);
  const [openerror, setopenerror] = useState(false);
  const [imageerror, setimageerror] = useState(false);
  const [email_validation, setEmail_validation] = useState(true);
  const [payee_Verified, setPayee_Verified] = useState(false);
  const [upi_Verified, setUpi_Verified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [upierrorfunc, setupierrorfunc] = useState(false);
  const [upierrormsg, setupierrormsg] = useState("");
  const [acerrorfunc, setacerrorfunc] = useState(false);
  const [Openerorimg, setOpenerrorimg] = useState(false);
  const [messagearrimg, setmessagearrimg] = useState([]);
  
  const [gsttrd,setgsttrd]=useState(profileDetails[0]?.gst_payeename_verifi || false)

  const [acerrormsg, setacerrormsg] = useState("");
  const [dataChange, setDataChange] = useState("");
  const [openprevious, setOpenprevious] = useState(false);
  const [responsepayeename, setresponsepayeename] = useState(
    profileDetails[0]?.Propertyowners &&
      profileDetails[0]?.Propertyowners[0]?.responsepayeename
  );
  // const [clicked, setClicked] = useState(false);
  let payeenamematchvalidation;

  const [bankverified, setbankverifiedfunc] = useState(bankac_name);

  let getimagepath = [];

  const [DataSource, SetDataSource] = useState([]);

  const removefile = (e) => {
    profile_acVerifiedbut(false);
    setPayee_Verified(false);
    payment_verified(false);
  };
  const handleClick = () => {
    // setClicked(true);
    setmessagearr([]);

    let contact_type = document.querySelector(
      ".contact_type > div > span > input"
    );
    let contact_Name = document.querySelector(".contact_Name");
    let Email_Id = document.querySelector(".Email_Id");
    let Mobile_No = document.querySelector(".Mobile_No");
    let landline = document.querySelector(".landline");

    let remove_error = document.querySelectorAll(".border_red");

    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (contact_type?.value === "") {
      setopenerror(true);
      setmessagearr((pre) => [...pre, "* Please enter the contact type"]);
      contact_type?.closest(".ant-select-selector").classList.add("border_red");
    }
    if (contact_Name?.value === "") {
      setopenerror(true);
      setmessagearr((pre) => [...pre, "* Please enter the contact name"]);
      contact_Name?.classList.add("border_red");
    }
    if (Email_Id?.value === "") {
      setopenerror(true);
      setmessagearr((pre) => [...pre, "* Please enter the email"]);
      Email_Id?.classList.add("border_red");
      setEmail_validation(false);
    }

    if (Mobile_No?.value === "") {
      // debugger
      setopenerror(true);
      // seterrorEmailid("")
      setmessagearr((pre) => [...pre, "* Please enter the mobile no"]);
      Mobile_No?.classList.add("border_red");
    } else {
      if (Mobile_No?.value.length < 10) {
        setmessagearr((pre) => [...pre, "* Mobile  should be 10 digits"]);
        Mobile_No?.classList.add("border_red");
      }
    }
    if (landline?.value.length > 0) {
      if (landline?.value.length < 8) {
        setmessagearr((pre) => [...pre, "* Minimum 8 digits required for landline no"]);
        landline?.classList.add("border_red");
      }
    }

    if (
      contact_type?.value === "" ||
      contact_Name?.value === "" ||
      Email_Id?.value === "" ||
      Mobile_No?.value === "" ||
      Mobile_No?.value.length < 10 ||
      (landline?.value.length > 0 && landline?.value.length < 8)
    ) {
      setopenerror(true);
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email_Id?.value)) {
        setopenerror(true);
        setmessagearr((pre) => [...pre, "* Please enter the valid email"]);
        Email_Id?.classList.add("border_red");
      } else {
        setIsLoading(true);
        const newcontactDetail = {
          key: Math.floor(Math.random() * 100),
          contactType: multispacetrim(contacttypeval),
          contactName: multispacetrim(contactName),
          Email: multispacetrim(Email),
          mobileno: Mobileno,
          landlineno: multispacetrim(landlineno),
        };

        // newcontactDetail.key = newcontactDetail.key + 1 || 1;

        //  if(newcontactDetail.)
        SetDataSource((pre) => {
          return [...pre, newcontactDetail];
        });

        setcontacttypeval("");
        setcontactName("");
        setEmail("");
        setMobileno("");
        setlandlineno("");
        setIsLoading(false);
      }
    }
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleChange = (value) => {};
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  if (window.performance) {
  }
  // const handleChangephoto =  (info) =>
  // {

  //   setFileList(info.file.originFileObj)

  // }

  const handleChangephoto = ({ fileList: newFileList }) => {
    // console.log(fileList)
    // console.log(newFileList)

//pdf restriction
let fileextcheck = newFileList[0]?.name.split(".")

if(fileextcheck !== undefined && (fileextcheck[1] == 'jpg' || fileextcheck[1] == 'jpeg' || fileextcheck[1] == 'png')) {
  setFileList(newFileList);
  setfile(newFileList[0]?.originFileObj);
 }
 else {
  if(newFileList?.length > 0)
  {
  setOpenerrorimg(true);
  }
  setmessagearrimg([ "* Supported file formats are (.jpg, .jpeg, .png)"]);
 setFileList([]);
 setfile([]);
 }

   
    setDataChange("You have unsaved data, do you want to continue?");
    profile_acVerifiedbut(false);
    setPayee_Verified(false);
    payment_verified(false);
    // setfilename(newFileList[0].name)
  };

  const [enteredData, setEnteredData] = useState({
    // contactType: '',
    contactName: "",
    Email: "",
    // mobileno:'',
    // landlineno:''
  });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelbtn = () => {
    setOpen(false);
    settradeerroropen(false)
  };

  const handlebtncancel = () => {
    setopenerror(false);
    setOpenerrorimg(false);
  };
  const [dataentered, setdataentered] = useState([]);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
          //fontWeight:"bold",
          color:"red",
fontSize:"12px"
        }}
      >
       <span style={{color:"black"}}> Upload{" "}</span>
        <br /><small style={{color:"black"}}>
          (Maximum upload size limit 2mb)
        </small>
      
         <br/>
          Supported file formats<br />
          (.jpg, .jpeg, .png) <small>Only</small>
        <span className="m-red">*</span>
      </div>
    </div>
  );

  const verifybankaccounthash = async () => {
    setmessagearr([]);
    // let p_name = document.querySelector(".p_name");
    let b_name = document.querySelector(".b_name");
    let a_no = document.querySelector(".a_no");
    let ifsc = document.querySelector(".ifsc");
    let a_type = document.querySelector(
      ".a_type .ant-select-selection-item"
    ).innerHTML;
    let a_type_text = document.querySelector(
      ".a_type .ant-select-selection-item"
    );
    let b_address = document.querySelector(".b_address");
    let c_leaf = document.querySelector(".ant-upload");

    let remove_error = document.querySelectorAll(".border_red");
    remove_error.forEach((item) => item.classList.remove("border_red"));
    if (a_no?.value === "") {
      seterroraccountno();
      setmessagearr((pre) => [...pre, "* Please enter the account no"]);
      a_no?.classList.add("border_red");
    }
    if (ifsc?.value === "") {
      seterrorPayeename("");
      seterroraccountno("");
      //seterrorifsccode("Please enter the IFSC Code");
      setmessagearr((pre) => [...pre, "* Please enter the IFSC code"]);
      ifsc?.classList.add("border_red");
    }

    // if (p_name?.value === "") {
    //   seterroraccountno();
    //   setmessagearr((pre) => [...pre, "* Please enter the payee name"]);
    //   p_name?.classList.add("border_red");
    // }
    if (b_name?.value === "") {
      seterroraccountno();
      setmessagearr((pre) => [...pre, "* Please enter the bank name"]);
      b_name?.classList.add("border_red");
    }

    if (a_type === "") {
      setmessagearr((pre) => [...pre, "* Please enter the account type"]);
      a_type_text?.closest(".ant-select-selector").classList.add("border_red");
      //ant-select-selector
    }
    // if (b_address?.value === "") {
    //   setmessagearr((pre) => [...pre, "* Please enter the Branch Address"]);
    //   b_address?.classList.add("border_red");
    // }
    let filedata = document.querySelector(".ant-upload");
    if (fileList.length === 0) {
      setmessagearr((pre) => [...pre, "* Please upload cheque leaf"]);
      let fileuploadeddata = document.querySelector(
        ".ant-upload-list-picture-card-container"
      );
      if (!fileuploadeddata) {
        filedata?.classList.add("border_red");
      }
    }
    if (c_leaf?.value === "") {
      setmessagearr((pre) => [...pre, "* Please upload cheque leaf"]);
      c_leaf?.classList.add("border_red");
    } else {
      if (imageerror === true) {
        setmessagearr((pre) => [...pre, "* Image should be less than 2 mb"]);
      }
    }

    if (
      // p_name?.value === "" ||
      b_name?.value === "" ||
      a_no?.value === "" ||
      ifsc?.value === "" ||
      a_type?.value === "" ||
      // b_address?.value === "" ||
      fileList.length === 0 ||
      imageerror === true
    ) {
      setOpen(true);
      setValidgsttradename(false);
    } else {
      setIsLoading(true);
      const datanew = {
        Authorization: `780070794E|${Accountnumber}|${IFSCcode}|8EA5D0A2F2`,
        Accountnumber: Accountnumber,
        IFSCcode: IFSCcode,
      };
      const res = axios
        .post(`${onboardapi}/hash`, datanew)
        // .post("http://localhost:3000/API/hash",datanew)
        // .post("https://hotelonboardtestapi.staysimplyfied.com/API/hash",datanew)
        .then((res) => {
          // check for name match validation

          // setAuthorization(res.data.data);
          // const data = {
          //   key: "780070794E",
          //   account_no: Accountnumber,
          //   ifsc: IFSCcode,
          // };
          // setIsLoading(false);
          // setPayee_Verified(true);
          // payment_verified(true)
          // // setshowverifypayeebtn(false);
          // // setshowverifypayee(true);
          // profile_acVerifiedbut(true);

          // const response = axios
          //   .post(
          //     "https://wire.easebuzz.in/api/v1/beneficiaries/bank_account/verify/",
          //     data,
          //     {
          //       headers: {
          //         // 'Content-Type': 'application/json',

          //         Authorization: res.data.data,
          //       },
          //     }
          //   )
          // .then((res) => {

          if (res.data.data.is_valid == true) {
           

            // name match check
            let payee_lover_case = res?.data?.data?.account_name?.toLowerCase();
            let tradeleagel_lower_case = (
              basic[0]?.tradename || basic[0]?.legalname
            )?.toLowerCase();

            if (
              payee_lover_case?.includes(
                tradeleagel_lower_case?.split(" ")[0]
              ) === false ||
              tradeleagel_lower_case?.includes(
                payee_lover_case?.split(" ")[0]
              ) === false
            ) {
              seterrormessagearrpayee((pre) => [
                "* Payee name doesn't match GSTIN trade name do you want to continue?",
              ]);
              setValidgsttradename(true);
              settradeerroropen(true);
              setgsttrd(true);
            } else {
              setValidgsttradename(false);

              setgsttrd(false);
            }
            bankNamefunc(res.data.data);
            setIsLoading(false);
            setPayeename(res.data.data.account_name);
            setresponsepayeename(res.data.data.account_name);
            setbankverifiedfunc(res.data.data);
            setacerrorfunc(false);
            setPayee_Verified(true);
            payment_verified(true);
            profile_acVerifiedbut(true);
            // name match check
          } else {
            setIsLoading(false);
            setacerrorfunc(true);
            profile_acVerifiedbut(false);
            setmessagearr((pre) => [
              ...pre,
              "* Check your A/c number / IFSC code and try again",
            ]);
            setopenerror(true);
            //setOpen(true)
            a_no?.classList.add("border_red");
            ifsc?.classList.add("border_red");
          }
        })
        .catch((err) => {
          bankNamefunc({});
          setIsLoading(false);

          setmessagearr((pre) => [
            ...pre,
            "* Check your A/c number / IFSC code and try again",
          ]);
          setopenerror(true);
          // setPayee_Verified(false);
          // payment_verified(false)
          // profile_acVerifiedbut(true)
        });
      // })
      // .catch((err) => {

      //   setIsLoading(false);

      // });
    }
  };

  const verifyupiaccounthash = () => {
    setmessagearr([]);
    let upi_Id = document.querySelector(".upi_id");
    let upi_name = document.querySelector(".upi_name");
    let upi_mobile_no = document.querySelector(".upi_mobile_no");

    let remove_error = document.querySelectorAll(".border_red");
    remove_error.forEach((item) => item.classList.remove("border_red"));

    if (upi_Id?.value == "") {
      seterrorupiid("Please enter the UPI id");
      setmessagearr((pre) => [...pre, "* Please enter the UPI id"]);
      upi_Id?.classList.add("border_red");
    }
    if (upi_name?.value == "") {
      setmessagearr((pre) => [...pre, "* Please enter the UPI name"]);
      upi_name?.classList.add("border_red");
    }
    // if (upi_mobile_no?.value == "") {
    //   setmessagearr((pre) => [...pre, "* Please enter the Upi Mobile No"]);
    //   upi_mobile_no?.classList.add("border_red");
    // }
    if (
      upi_Id?.value == "" ||
      //upi_name?.value == "" ||
      (upi_mobile_no?.value == "" &&
        profileacverified === true &&
        profileupiverified === true)
    ) {
      setOpen(true);
    } else {
      setIsLoading(true);
      const datanew = {
        Authorization: `780070794E|${UpiId}|8EA5D0A2F2`,
        UpiId: UpiId,
      };

      const res = axios

        // .post("https://hotelonboardtestapi.staysimplyfied.com/API/hashupi",datanew)
        .post(`${onboardapi}/hashupi`, datanew)

        .then((res) => {
          if (res.data.data.vpa_status === "valid") {
            // setshowverifysymb(true);
            // setshowverifybtn(false);
            profile_upi_Verifiedbut(true);
            setIsLoading(false);
            upiNamefunc(res.data.data);
          } else {
            //setupierrormsg("Check your UPI Id and try again")
            setmessagearr((pre) => [
              ...pre,
              upi_Id?.value.length === 0
                ? "* Please enter UPI id"
                : "* Invalid UPI id",
            ]);
            setopenerror(true);
            profile_upi_Verifiedbut(false);
            setupierrorfunc(true);
            setIsLoading(false);
          }
          upi_verified(true);
          setUpi_Verified(true);
          setIsLoading(false);
          setupierrorfunc(false);
        })
        .catch((err) => {
          upi_verified(true);
          setUpi_Verified(true);
          profile_upi_Verifiedbut(false);
          setIsLoading(false);
          setmessagearr((pre) => [
            ...pre,
            upi_Id?.value.length === 0
              ? "* Please enter UPI id"
              : "* Invalid UPI id",
          ]);
          //setupierrormsg("*  Check your UPI id and try again");
          setopenerror(true);
          setupierrorfunc(true);
        });
    }
  };

  const sendcontactdetails = () => {
    setmessagearr([]);
    let contact_details = document.querySelectorAll(
      ".ant-table-row.ant-table-row-level-0"
    );

    if (contact_details?.length < 2) {
      setmessagearr((pre) => [...pre, "* Mimimum 2 contact details required"]);
    }
    if (profileacverified === false) {
      setmessagearr((pre) => [...pre, "* Please verify payment information"]);
    }
    let upi_Id = document.querySelector(".upi_id");
    if (upi_Id.value !== "") {
      if (profileupiverified === false) {
        setmessagearr((pre) => [...pre, "* Please verify UPI information"]);
      }
    }
    let upi_M_No = document.querySelector(".upi_mobile_no");
    if (upi_M_No.value !== "" && upi_M_No.value.length < 10) {
      setmessagearr((pre) => [...pre, "*  Mobile no must be 10 digits"]);
    }

    if (fileList?.length === 0) {
      setmessagearr((pre) => [...pre, "* Please upload cheque leaf"]);
    }

    // if(basic[0]?.tradename?.includes(responsepayeename?.split(" ")[0])===false || responsepayeename?.includes(basic[0]?.tradename?.split(" ")[0])===false)
    // {
    //   setmessagearr((pre)=>[...pre,"* Payee name doesn't match GSTIN trade name"]);
    // }
    //nitish if((responsepayeename && responsepayeename)?.includes(basic[0]?.tradename?.split(" ")[0]) === false || basic[0]?.tradename?.includes((responsepayeename && responsepayeename)?.split(" ")[0]) === false)
    // {
    //   setmessagearr((pre)=>[...pre,"* Payee name doesn't match GSTIN trade name"]);
    // }

    // let payee_lover_case = (
    //   (responsepayeename && responsepayeename) ||
    //   (bankverified && bankverified?.account_name)
    // )?.toLowerCase();
    // let tradeleagel_lower_case = (
    //   basic[0]?.tradename || basic[0]?.legalname
    // )?.toLowerCase();

    // //replace

    // if (
    //   payee_lover_case?.includes(tradeleagel_lower_case?.split(" ")[0]) ===
    //     false ||
    //   tradeleagel_lower_case?.includes(payee_lover_case?.split(" ")[0]) ===
    //     false
    // ) {
    //   if (profileacverified === false) {
    //     setValidgsttradename(false);
    //     setmessagearr((pre) => [...pre, "* Please verify payment information"]);
    //   } else {
    //     setValidgsttradename(true);
    //     setmessagearr((pre) => [
    //       ...pre,
    //       "* Payee name doesn't match GSTIN trade name do you want to continue?",
    //     ]);
    //   }
    // }

    let contact_type = document.querySelector(
      ".contact_type > div > span > input"
    );
    let contact_Name = document.querySelector(".contact_Name");
    let Email_Id = document.querySelector(".Email_Id");
    let Mobile_No = document.querySelector(".Mobile_No");
    let landline = document.querySelector(".landline");

    if (
      contact_Name?.value !== "" ||
      Email_Id?.value !== "" ||
      Mobile_No?.value !== "" ||
      landline?.value !== ""
    ) {
      setmessagearr([]);
      setmessagearr(["* Please add contact details to the list"]);
      setOpen(true);
    } else if (
      contact_details?.length < 2 ||
      profileacverified === false ||
      (upi_Id?.value !== "" && profileupiverified === false) ||
      (upi_M_No.value !== "" && upi_M_No.value.length < 10) ||
      fileList?.length === 0 
      // ||
      // ((payee_lover_case?.includes(tradeleagel_lower_case?.split(" ")[0]) ===
      //   false ||
      //   tradeleagel_lower_case?.includes(payee_lover_case?.split(" ")[0]) ===
      //     false) &&
      //   validgsttradename === true)
    ) {
      setOpen(true);
    } else {
      // debugger
      setIsLoading(true);

      let payee_name = document.querySelector(".p_name");
      let payeenameobj = { account_name: payee_name?.value };
      bankNamefunc(payeenameobj || bankac_name);

      let Propertyowners = [
        {
          Payeename:
            multispacetrim(payee_name?.value) || bankac_name?.account_name,
          //document.querySelector(".p_name")?.value,
          Bankname:
            multispacetrim(Bankname) ||
            document.querySelector(".b_name")?.value,
          Accountnumber:
            Accountnumber || document.querySelector(".a_no")?.value,
          IFSCcode:
            multispacetrim(IFSCcode) || document.querySelector(".ifsc")?.value,
          AccountType:
            AccountType ||
            document.querySelector(".a_type .ant-select-selection-item")
              .innerHTML,
          branchAddress:
            multispacetrim(branchAddress) ||
            document.querySelector(".b_address")?.value,
          checkleafpath: fileList,
          UpiId: UpiId || document.querySelector(".upi_id")?.value,
          Upiname: Upiname || upi_name?.vpa_name,
          //document.querySelector(".upi_name")?.value,
          Upimobilenumber:
            Upimobilenumber || document.querySelector(".upi_mobile_no")?.value,
          chkleafimagepath: chkleafpath,
          responsepayeename:
            responsepayeename ||
            profileDetails[0]?.Propertyowners[0]?.responsepayeename,
        },
      ];

      setmessagearr([]);
      setData1(DataSource);
      let contactarr = DataSource;
      addToProfile([
        {
          Propertyowners: Propertyowners,
          contactarr: contactarr,
          gst_payeename_verifi: gsttrd,
        },
      ]);
      var form = document.getElementById("chkuploadform");
      const formData = new FormData(form);
      formData.append(
        "file",
        file ||
          profileDetails[0]?.Propertyowners[0]?.checkleafpath[0]?.originFileObj
      );
      // formData.append("fileName", filename);
      let response = axios
        .post(`${onboardapi}/imageupload`, formData)
        // .post("https://hotelonboardtestapi.staysimplyfied.com/API/imageupload",formData)
        .then((res) => {
          setchkleafpath(res.data?.path);
          getimagepath.push(res.data?.path);

          if (res?.data?.path !== "" || res?.data?.path !== undefined) {
            setIsLoading(false);

            navigate("/Publish");
            //window.location.replace('/Publish');
          }
        })
        .catch((error) => {
          // setIsLoading(false);
        });

      profileImagePathfunc(getimagepath);
    }
  };

  // const fileinsert = () => {

  // };

  useEffect(() => {
    if (UpiId === "" || UpiId === undefined) {
      profile_upi_Verifiedbut(false);
      setUpi_Verified(false);
    }

    if (Accountnumber === "" || Accountnumber === undefined) {
      profile_acVerifiedbut(false);
      setPayee_Verified(false);
      payment_verified(false);
    }
    window.scrollTo(0, 0);
    window.scroll(0, 20);
    if (profileDetails[0]?.contactarr) {
      SetDataSource(profileDetails[0]?.contactarr);
      setFileList(profileDetails[0]?.Propertyowners[0]?.checkleafpath);
    }
  }, []);

  const handleDelete = (key) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    const newData = DataSource.filter((item) => item.key !== key);

    SetDataSource(newData);
  };

  const okpreviouspage = () => {
    navigate("/photos");
    profile_acVerifiedbut(false);
    setPayee_Verified(false);
    payment_verified(false);
  };

  const showprevModal = () => {
    let contact_type = document.querySelector(
      ".contact_type > div > span > input"
    );
    let contact_Name = document.querySelector(".contact_Name");
    let Email_Id = document.querySelector(".Email_Id");
    let Mobile_No = document.querySelector(".Mobile_No");
    let landline = document.querySelector(".landline");

    // acc verify
    let p_name = document.querySelector(".p_name");
    let b_name = document.querySelector(".b_name");
    let a_no = document.querySelector(".a_no");
    let ifsc = document.querySelector(".ifsc");
    let a_type = document.querySelector(
      ".a_type .ant-select-selection-item"
    ).innerHTML;
    let a_type_text = document.querySelector(
      ".a_type .ant-select-selection-item"
    );
    let b_address = document.querySelector(".b_address");
    let c_leaf = document.querySelector(".ant-upload");
    // upi verification
    let upi_Id = document.querySelector(".upi_id");
    let upi_name = document.querySelector(".upi_name");
    let upi_mobile_no = document.querySelector(".upi_mobile_no");

    if (
      JSON.stringify(DataSource) !==
      JSON.stringify(profileDetails[0]?.contactarr)
    ) {
      setDataChange("You have unsaved data, do you want to continue?");
    }
    //(JSON.stringify(DataSource && DataSource) === JSON.stringify(profileDetails?.contactarr) ||

    if (
      (contact_type.value === undefined ||
        contact_type?.value === "" ||
        contact_type?.value === "Front Office") &&
      (contact_Name?.value === "" ||
        contact_Name?.value === undefined ||
        contact_Name?.value === null) &&
      (Email_Id?.value === "" ||
        Email_Id?.value === undefined ||
        Email_Id?.value === null) &&
      (Mobile_No?.value === "" ||
        Mobile_No?.value === undefined ||
        Mobile_No?.value === null) &&
      (landline?.value === "" ||
        landline?.value === undefined ||
        landline?.value === null) &&
      (JSON.stringify(DataSource) ===
        JSON.stringify(profileDetails[0]?.contactarr) ||
        DataSource?.length === 0) &&
      (p_name?.value === "" ||
        p_name?.value === undefined ||
        p_name?.value === null) &&
      (b_name?.value === "" ||
        b_name?.value === undefined ||
        b_name?.value === null) &&
      (a_no?.value === "" ||
        a_no?.value === undefined ||
        a_no?.value === null) &&
      (ifsc?.value === "" ||
        ifsc?.value === undefined ||
        ifsc?.value === null) &&
      (a_type_text?.value === "" ||
        a_type_text?.value === undefined ||
        a_type_text?.value === null) &&
      (b_address?.value === "" ||
        b_address?.value === undefined ||
        b_address?.value === null) &&
      fileList.length === 0 &&
      (upi_Id?.value === "" ||
        upi_Id?.value === undefined ||
        upi_Id?.value === null) &&
      (upi_mobile_no?.value === "" ||
        upi_mobile_no?.value === undefined ||
        upi_mobile_no?.value === null)
    ) {
      setmessagearr([]);
      navigate("/photos");
    } else {
      if (dataChange !== "") {
        setmessagearr([dataChange]);
        setOpenprevious(true);
      } else {
        navigate("/photos");
      }
    }
  };

  const handleCancelerror = () => {
    setOpenprevious(false);
  };
  const gstpayeenameverity = () => {
    setValidgsttradename(false);
    //validgsttradename = false;
    setOpen(false);
    settradeerroropen(false);
    setIsLoading(false);
    setgsttrd(true);
  };
  const gstpayeenoverity = () => {
    settradeerroropen(false);
    setbankverifiedfunc([]);
    setIsLoading(false);
    setPayeename([]);
    setresponsepayeename([]);
    setacerrorfunc(true);
    setPayee_Verified(false);
    payment_verified(false);
    profile_acVerifiedbut(false);
  };
  return (
    <div>
      {isLoading && (
        <div className="loaderloading">
          <div className="example">
            <Spin />{" "}
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              Loading ...
            </span>
          </div>
        </div>
      )}
      <div>
        <nav className="row">
          <a className="navbar-brand col-md-2 text-center mobile-show" href="#">
            <img
              className=""
              src={
                logo_img
              }
              alt=""
              width={"120px"}
              height={"85px"}
            />
          </a>
          <span className="text-center col-md-12 d-flex justify-content-center align-items-center">
            <h3>
              <b>Property Onboarding</b>
            </h3>
          </span>
        </nav>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-2 no-border-l h100"
              style={{ background: "#fff", borderLeft: "none" }}
            >
              <Card className="col-md-12 h-100 side-menu">
                <Steps
                  size="small"
                  className="mt-md-5 cursor-not-allowed mobile-hide"
                  direction="vertical"
                  current={5}
                >
                  <Steps.Step title={<NavLink to="/">Basics</NavLink>} />

                  <Steps.Step
                    title={<NavLink to="/location">Location</NavLink>}
                  />

                  <Steps.Step
                    title={<NavLink to="/facilities">Facilities</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/roomdet">Room details</NavLink>}
                  />
                  <Steps.Step title={<NavLink to="/photos">Photos</NavLink>} />
                  <Steps.Step
                    title={<NavLink to="/Profile">Contact</NavLink>}
                  />
                  <Steps.Step
                    title={<NavLink to="/Publish">Publish</NavLink>}
                  />
                </Steps>

                <a
                  className="navbar-brand col-md-2 text-center logo-display"
                  href="#"
                >
                  <img
                    className="logo_name"
                    src={
                      logo_img
                    }
                    alt=""
                  />
                </a>
                <p className="mt-sm-3 deskbrowser" style={{ fontSize: "12px" }}>
                  <span
                    className="fw-bold"
                    style={{ fontSize: "14px", marginBottom: "10px" }}
                  >
                    Supported Browsers :{" "}
                  </span>
                  <br />{" "}
                  <span style={{ margin: "0px", lineHeight: "10px" }}></span>1.
                  Google Chrome,
                  <br />
                  2. Microsoft Edge,
                  <br />
                  3. Firefox.
                </p>
              </Card>
            </div>
            <div
              className="col-md-10 layout-ml"
              style={{ background: "rgb(227 245 255)" }}
            >
              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col-6  p-3">
                  <h3>Account details</h3>
                  <p>
                    Please provide your full legal name here for your contract
                    with HB. If additional information is needed to list your
                    property, we will contact you.
                  </p>
                </div>
                <div className="col-sm-6">
                  <img src={photoheadpic} alt="" height={"120px"} />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-lg-9 profile">
                  <h5>Contact details</h5>

                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          <b>
                            Contact Type&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        <br />
                        <AutoComplete
                          options={options}
                          style={{
                            width: "100%",
                          }}
                          id="message"
                          name="contactType"
                          value={contacttypeval}
                          onChange={onChangeautocomplete}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          className="mt-0 mb-2 contact_type"
                          placeholder="Contact Type"
                          onInput={propInput}
                          maxLength={30}
                        />
                      </div>

                      <div className="col-sm-6">
                        <label>
                          <b>
                            Contact Name&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        <br />
                        <Input
                          placeholder="Contact Name"
                          className="mt-sm-2 contact_Name"
                          name="contactName"
                          style={{
                            width: "100%",
                          }}
                          value={contactName}
                          onChange={(event) => {
                            setcontactName(
                              event.target.value.replaceAll("  ", " ")
                            );
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                            // setClicked(false);
                          }}
                          onInput={propInput}
                          maxLength={50}
                        ></Input>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          <b>
                            Email&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        <Input
                          placeholder="Email"
                          className="mt-sm-2 Email_Id"
                          name="Email"
                          type="text"
                          value={Email}
                          onChange={(event) => {
                            event.target.value.includes(",") === false &&
                              setEmail(event.target.value.replaceAll(" ", ""));
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                            // setClicked(false);
                          }}
                          onInput={propInput}
                          maxLength={50}
                        ></Input>
                      </div>
                      <div className="col-sm-6">
                        <label>
                          <b>
                            Mobile No&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        <br />
                        <Input
                          placeholder="Mobile No"
                          className="mt-sm-2 Mobile_No"
                          name="mobileno"
                          value={Mobileno}
                          pattern="[0-9]*"
                          onChange={(event) => {
                            setMobileno((v) =>
                              event.target.validity.valid
                                ? event.target.value
                                : v
                            );
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                            // setClicked(false);
                          }}
                          maxLength={10}
                        ></Input>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <label>
                          <b>Landline No</b>
                        </label>
                        <br />
                        <Input
                          placeholder="Landline No"
                          className="mt-sm-2 landline"
                          name="landlineno"
                          value={landlineno}
                          maxLength={15}
                          onChange={(event) => {
                            setlandlineno(
                              event.target.value.replace(/\D/g, "")
                            );
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                            // setClicked(false);
                          }}
                          onInput={propInput}
                        ></Input>
                      </div>

                      <div className="col-sm-6 mt-2  mt-md-0">
                        <label className="d-m-none">
                          <b></b>
                        </label>
                        <br className="d-m-none" />
                        <Button
                          type="primary"
                          className="float-end float-sm-start dblclick"
                          onClick={handleClick}
                          style={{
                            width: "",
                            marginLeft: "0px !important",
                          }}
                          // disabled={clicked}
                        >
                          Add Contact Details to the list
                        </Button>
                      </div>
                    </div>
                  </Card>

                  <div
                    className="col-sm-12 my-3"
                    style={{
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                      overflow: "auto",
                    }}
                  >
                    <Table
                      dataSource={DataSource}
                      style={{ background: "#fff", minWidth: "600px" }}
                      pagination={false}
                    >
                      <Column
                        title="Contact Type"
                        key="contactType"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.contactType}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Contact Name"
                        key="contactName"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.contactName}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Email"
                        key="Email"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.Email}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Mobile No"
                        key="mobileno"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.mobileno}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Landline No"
                        key="landlineno"
                        render={(_, record) => (
                          <Space size="middle">
                            <p>{record?.landlineno}</p>
                          </Space>
                        )}
                      />
                      <Column
                        title="Action"
                        key="delete"
                        // render={(_, record) => (
                        //   <Space size="middle">

                        //   </Space>
                        // )}
                        render={(_, record) =>
                          DataSource.length >= 1 ? (
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => {
                                handleDelete(record?.key);
                                setDataChange(
                                  "You have unsaved data, do you want to continue?"
                                );
                              }}
                              okText={"Yes"}
                              cancelText={"No"}
                            >
                              <a
                                className="btn btn-danger btn-sm text-white d_v_c"
                                style={{ width: "30px" }}
                              >
                                <DeleteOutlined />
                              </a>
                            </Popconfirm>
                          ) : null
                        }
                      />
                    </Table>
                  </div>
                  <h5>Payment Information</h5>

                  <Card
                    className="mb-sm-3"
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          <b>
                            Account No&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>

                        <Input
                          placeholder="Account No"
                          pattern="[0-9]*"
                          onChange={(e) => {
                            setAccountnumber(textNum(e));

                            profile_acVerifiedbut(false);
                            setPayee_Verified(false);
                            payment_verified(false);
                            setValidgsttradename(true);
                            // validgsttradename = true;
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          className="mt-sm-2 a_no"
                          value={Accountnumber}
                          type="text"
                          min={0}
                          maxLength={20}
                        ></Input>
                      </div>
                      <div className="col-sm-4">
                        <label>
                          <b>
                            IFSC Code&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        <Input
                          placeholder="IFSC Code"
                          onChange={(event) => {
                            event.target.value.includes(" ") === false &&
                              setIFSCcode(event.target.value.toUpperCase());
                            profile_acVerifiedbut(false);
                            setPayee_Verified(false);
                            payment_verified(false);
                            setValidgsttradename(true);
                            // validgsttradename = true;
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          className="mt-sm-2 ifsc"
                          value={IFSCcode}
                          maxLength={12}
                          onInput={propInput}
                        ></Input>
                      </div>
                      <div className="col-sm-4">
                        <label>
                          <b>Payee Name</b>
                        </label>
                        <Input
                          placeholder="Payee Name"
                          disabled={true}
                          onChange={(event) => {
                            setPayeename(
                              event.target.value.replaceAll("  ", " ")
                            );
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          className="mt-sm-2 p_name"
                          value={Payeename}
                          onInput={propInput}
                          maxLength={60}
                        ></Input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <label>
                          <b>
                            Bank Name&nbsp;<span className="m-red">*</span>
                          </b>
                        </label>
                        <Input
                          placeholder="Bank Name"
                          onChange={(event) => {
                            setBankname(
                              event.target.value.replaceAll("  ", " ")
                            );
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          className="mt-sm-2 b_name"
                          value={Bankname}
                          onInput={propInput}
                          maxLength={50}
                        ></Input>
                      </div>

                      <div className="col-sm-4">
                        <label>
                          <b>Account Type</b>
                        </label>
                        <Select
                          onChange={(event) => {
                            setAccounttype(event);
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          value={AccountType || "Current"}
                          style={{ width: "100%" }}
                          // placeholder='Select Channel Manager'
                          options={[
                            {
                              value: "Current",
                              label: "Current",
                            },
                            {
                              value: "Saving",
                              label: "Saving",
                            },
                          ]}
                          className="a_type"
                        />
                      </div>

                      <div className="col-sm-4">
                        <label>
                          <b>Branch Address</b>
                        </label>
                        <Input
                          placeholder="Branch Address"
                          onChange={(event) => {
                            setbranchaddress(
                              event.target.value.replaceAll("  ", " ")
                            );
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          className="mt-sm-2 b_address"
                          value={branchAddress}
                          onInput={propInput}
                          maxLength={100}
                        ></Input>
                      </div>
                    </div>
                    <div className="row profile-blk">
                      <div className="col-sm-4">
                        <form id="chkuploadform">
                          <Upload style={{height: "120px"}}
                            // action="https://www.mocky.io/v2/5cc8019d300000980a0f55e76"
                            className="mt-sm-2 c_leaf prop_upload_error"
                            listType="picture-card"
                            fileList={fileList}
                            accept={".jpg, .jpeg, .png"}
                            action={"http://localhost:8000/"}
                            onPreview={handlePreview}
                            onChange={handleChangephoto}
                            multiple={false}
                            onRemove={removefile}
                            beforeUpload={(file) => {
                              const isLt2M = file.size / 1024 / 1024 < 2;
                              if (!isLt2M) {
                                setimageerror(true);
                                return true;
                              } else {
                                setimageerror(false);
                                return false;
                              }
                            }}
                          >
                            {fileList.length >= 1 ? null : uploadButton}
                          </Upload>
                          <Modal
                            open={previewOpen}
                            title={previewTitle}
                            footer={[
                              <Button
                                key="back"
                                className="dblclick"
                                onClick={handleCancel}
                              >
                                Close
                              </Button>,
                            ]}
                            onCancel={handleCancel}
                            maskClosable={false}
                          >
                            <img
                              alt="example"
                              style={{
                                width: "100%",
                              }}
                              src={previewImage}
                              maskClosable={false}
                            />
                          </Modal>
                        </form>
                      </div>

                      <div className="col-sm-8 mt-2 mt-sm-5">
                        {profileacverified === false ? (
                          <Button
                            type="primary"
                            className="dblclick"
                            onClick={verifybankaccounthash}
                          >
                            Verify Account
                          </Button>
                        ) : (
                          <>
                            <span className="verified_but">
                              <CheckOutlined style={{ color: "#02cc02" }} />{" "}
                              <span
                                style={{ color: "#02cc02", fontWeight: "bold" }}
                              >
                                {" "}
                                &nbsp;Verified&nbsp;
                              </span>
                            </span>
                            <b
                              style={{
                                opacity: profileacverified === true ? 1 : 0,
                              }}
                            >
                              {" "}
                              Name :{" "}
                            </b>
                            <span style={{ color: "black" }}>
                              {" "}
                              {bankverified && bankverified?.account_name}
                            </span>
                          </>
                        )}
                        {<p>{acerrorfunc === true ? acerrormsg : ""}</p>}
                        <br />
                        {/* <CloseOutlined style={{color:"red"}}/><span style={{color:"red"}}>Invalid Bank Account</span> */}
                      </div>
                    </div>
                  </Card>
                  <h5 className="mt-3 mt-md-0">UPI Information</h5>
                  <Card
                    style={{
                      background: "#f4f4f4",
                      boxShadow: "rgb(207 202 202) 0px 0px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                        <label>
                          <b>UPI ID</b>
                        </label>
                        <div className="row">
                          <div className="col-8 col-md-9">
                            <Input
                              placeholder="UPI ID"
                              onChange={(event) => {
                                setUpiid(
                                  event.target.value.replaceAll(" ", "")
                                );
                                profile_upi_Verifiedbut(false);
                                setUpi_Verified(false);
                                upiNamefunc({});
                                setDataChange(
                                  "You have unsaved data, do you want to continue?"
                                );
                              }}
                              className="mt-sm-2 col-9 upi_id"
                              value={UpiId}
                              maxLength={50}
                            ></Input>
                            <p>
                              {upierrorfunc === true ? upierrormsg : ""}
                              <b
                                style={{
                                  opacity: profileupiverified === true ? 1 : 0,
                                }}
                              >
                                Name :{" "}
                              </b>
                              {profileupiverified === true && (
                                <span style={{ color: "black" }}>
                                  {" "}
                                  {upi_name?.vpa_name ||
                                    profileDetails[0]?.Propertyowners[0]
                                      ?.Upiname}
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-3 col-md-3 ps-0">
                            {profileupiverified === false ? (
                              <Button
                                type="primary"
                                className="dblclick"
                                onClick={verifyupiaccounthash}
                              >
                                Verify UPI
                              </Button>
                            ) : (
                              <>
                                <span className="verified_but">
                                  <CheckOutlined style={{ color: "#02cc02" }} />{" "}
                                  <span
                                    style={{
                                      color: "#02cc02",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {" "}
                                    &nbsp;Verified &nbsp;
                                  </span>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-sm-6 col-md-4 mt-2 mt-sm-0">
                        <label>
                          <b>UPI Name&nbsp;<span className="m-red">*</span></b>
                        </label>
                        <Input
                          placeholder="UPI Name"
                          onChange={(event) => {
                            setUpiname(event.target.value);
                          }}
                          className="mt-sm-2 upi_name"
                          defaultValue={profileDetails?.Propertyowners && profileDetails?.Propertyowners[0]?.Upiname || Upiname
                          }
                        ></Input>
                      </div> */}

                      <div className="col-sm-6 col-md-4 mt-2 mt-sm-0">
                        <label>
                          <b>UPI&nbsp;Mobile&nbsp;No</b>
                        </label>
                        <Input
                          placeholder="UPI Mobile No"
                          value={Upimobilenumber}
                          pattern="[0-9]"
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setUpimobilenumber(textNum(e));
                            // profile_upi_Verifiedbut(false);
                            //  setUpi_Verified(false);
                            setDataChange(
                              "You have unsaved data, do you want to continue?"
                            );
                          }}
                          className="mt-sm-2 upi_mobile_no"
                          type="text"
                          maxLength={10}
                          min={0}
                        ></Input>
                      </div>
                    </div>
                  </Card>

                  <div className="float-end mt-3 mt-sm-3 mb-4">
                    <Button
                      type="primary"
                      style={{ minWidth: "100px", marginRight: "5px" }}
                      onClick={showprevModal}
                      className="dblclick"
                    >
                      Previous
                    </Button>
                    {/* onClick={verifyupiaccount} */}
                    <Button
                      type="primary"
                      className="ms-sm-3 dblclick"
                      style={{ minWidth: "100px" }}
                      onClick={sendcontactdetails}
                    >
                      Save & Continue
                      {/* <NavLink to="/Publish"></NavLink> */}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="error_msg"
        title="Error message"
        open={open}
        onCancel={handleCancelbtn}
        footer={[
         
            <Button
              key="back5"
              type="primary"
              className="dblclick"
              onClick={handleCancelbtn}
            >
              Close
            </Button>
         
        ]}
        maskClosable={false}
      >
        {errormessagearr &&
          [...new Set(errormessagearr)].map((item, index) => (
            <p key={index}>{item}</p>
          ))}
      </Modal>

      <Modal
        className="error_msg"
        title="Error message"
        open={openerror}
        onCancel={handlebtncancel}
        footer={[
          <Button
            key="back2"
            type="primary"
            className="dblclick"
            onClick={handlebtncancel}
          >
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        {errormessagearr &&
          [...new Set(errormessagearr)].map((item, index) => (
            <p key={index}>{item}</p>
          ))}
      </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={Openerorimg}
        onCancel={handlebtncancel}
        footer={[
          <Button
            key="back2"
            type="primary"
            className="dblclick"
            onClick={handlebtncancel}
          >
            Close
          </Button>,
        ]}
        maskClosable={false}
      >
        {messagearrimg &&
                messagearrimg.map((item, index) => <p key={index}>{item}</p>)}
      </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={openprevious}
        onCancel={handleCancelerror}
        footer={[
          <Button
            key="back"
            type="primary"
            className="dblclick"
            onClick={okpreviouspage}
          >
            Ok
          </Button>,
          <Button
            key="back1"
            type="primary"
            className="dblclick"
            onClick={handleCancelerror}
          >
            Cancel
          </Button>,
        ]}
        maskClosable={false}
      >
        * You have unsaved data, do you want to continue?
      </Modal>
      <Modal
        className="error_msg"
        title="Error message"
        open={tradeerroropen}
        onCancel={handleCancelbtn}
        footer={[
          validgsttradename === true && (
            <>
              <Button
                key="bac4"
                type="primary"
                className="dblclick"
                onClick={gstpayeenameverity}
              >
                Yes
              </Button>
              &nbsp;
              <Button
                key="back3"
                type="primary"
                className="dblclick"
                onClick={gstpayeenoverity}
              >
                No
              </Button>
            </>
          ),
          // <Button key="back" type="primary" onClick={handleCancelbtn}>
          //   Close
          // </Button>,
        ]}
        maskClosable={false}
      >
        {errormessagearrpayee &&
          [...new Set(errormessagearrpayee)].map((item, index) => (
            <p key={index}>{item}</p>
          ))}
      </Modal>
    </div>
  );
}
